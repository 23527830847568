

import img1 from '../images/product/product2.jpg'
import img2 from '../images/product/product32.jpg'
import img3 from '../images/product/product33.jpg'
import img4 from '../images/product/product34.jpg'

import img5 from '../images/product/product35.jpg'
import img6 from '../images/product/product36.jpg'
import img7 from '../images/product/product37.jpg'
import img8 from '../images/product/product38.jpg'

import img9 from '../images/product/product39.jpg'
import img10 from '../images/product/product40.jpg'
import img11 from '../images/product/product41.jpg'
import img12 from '../images/product/product42.jpg'

import avt1 from '../images/author/author1.png'
import avt2 from '../images/author/author2.png'
import avt3 from '../images/author/author3.png'
import avt4 from '../images/author/author4.png'
import avt5 from '../images/author/author5.png'
import avt6 from '../images/author/author6.png'
import avt7 from '../images/author/author7.png'
import avt8 from '../images/author/author8.png'
import avt9 from '../images/author/author9.png'
import avt10 from '../images/author/author10.png'
import avt11 from '../images/author/author11.png'
import avt12 from '../images/author/author12.png'

const dataHotpick = [
    {
        id: 1,
        img: img1,
        avt: avt1,
        title: 'Giulia Glur #32',
        create: 'Magnus Perry',
        price : '0.0041'
    },
    {
        id: 2,
        img: img2,
        avt: avt2,
        title: 'Giulia Glur #32',
        create: 'Frederick Dixon',
        price : '0.0041'
    },
    {
        id: 3,
        img: img3,
        avt: avt3,
        title: 'Archetype #588',
        create: 'Theo Watts',
        price : '0.0041'
    },
    {
        id: 4,
        img: img4,
        avt: avt4,
        title: '3DPunks #070',
        create: 'Neville Gutierrez',
        price : '0.0041'
    },
    {
        id: 5,
        img: img5,
        avt: avt5,
        title: 'Angelic Nyan Cat',
        create: 'Frederick Dixon',
        price : '0.0041'
    },
    {
        id: 6,
        img: img6,
        avt: avt6,
        title: 'Sweet Baby #1',
        create: 'Stephan Neal',
        price : '0.0041'
    },

    {
        id: 7,
        img: img7,
        avt: avt7,
        title: 'Giulia Glur #32',
        create: 'Magnus Perry',
        price : '0.0041'
    },
    {
        id: 8,
        img: img8,
        avt: avt8,
        title: 'Giulia Glur #32',
        create: 'Frederick Dixon',
        price : '0.0041'
    },

    {
        id: 9,
        img: img9,
        avt: avt9,
        title: 'Giulia Glur #32',
        create: 'Magnus Perry',
        price : '0.0041'
    },
    {
        id: 10,
        img: img10,
        avt: avt10,
        title: 'Giulia Glur #32',
        create: 'Frederick Dixon',
        price : '0.0041'
    },
    {
        id: 11,
        img: img11,
        avt: avt11,
        title: 'Archetype #588',
        create: 'Theo Watts',
        price : '0.0041'
    },
    {
        id: 12,
        img: img12,
        avt: avt12,
        title: '3DPunks #070',
        create: 'Neville Gutierrez',
        price : '0.0041'
    },
    
   
]

export default dataHotpick;