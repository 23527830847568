const menus = [
    {
        id: 1,
        name: 'Marketplace',
        links: '/home-v2',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Home 01',
        //         links: '/'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Home 02',
        //         links: '/home-v2'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Home 03',
        //         links: '/home-v3'
        //     },
        //     {
        //         id: 4,
        //         sub: 'Home 04',
        //         links: '/home-v4'
        //     },
        //     {
        //         id: 5,
        //         sub: 'Home 05',
        //         links: '/home-v5'
        //     },
        //     {
        //         id: 6,
        //         sub: 'Home 06',
        //         links: '/home-v6'
        //     },
        // ]
    },
    {
        id: 2,
        name: 'Explore',
        links: '/explore-v2',
        namesub: [
            // {
            //     id: 1,
            //     sub: 'Explore v1',
            //     links: '/explore-v1'
            // },
            {
                id: 2,
                sub: 'Explore',
                links: '/explore-v2'
            },
            // {
            //     id: 3,
            //     sub: 'Explore v3',
            //     links: '/explore-v3'
            // },
            // {
            //     id: 4,
            //     sub: 'Explore v4',
            //     links: '/explore-v4'
            // },
            {
                id: 5,
                sub: 'Collection',
                links: '/collection'
            },
            {
                id: 6,
                sub: 'Live Auction',
                links: '/live-auctions-v1'
            },
            // {
            //     id: 7,
            //     sub: 'Live Auction v2',
            //     links: '/live-auctions-v2'
            // },
            {
                id: 8,
                sub: 'Item Details',
                links: '/item-details-v1'
            },
            // {
            //     id: 9,
            //     sub: 'Item Details v2',
            //     links: '/item-details-v2'
            // },
        ]
    },
   
    {
        id: 5,
        name: 'Create',
        links: '/create',
    },
    {
        id: 6,
        name: 'Blog',
        links: '#',

        namesub: [
            {
                id: 1,
                sub: 'Blog',
                links: '/blog-v1'
            },
            // {
            //     id: 2,
            //     sub: 'Blog v2',
            //     links: '/blog-v2'
            // },
            // {
            //     id: 3,
            //     sub: 'Blog Details v1',
            //     links: '/blog-details-v1'
            // },
            {
                id: 4,
                sub: 'Blog Details',
                links: '/blog-details-v2'
            },

        ],
    },
    {
        id: 4,
        name: 'Account',
        links: '#',
        namesub: [
            // {
            //     id: 1,
            //     sub: 'Profile',
            //     links: '/dashboard '
            // },
           
            // {
            //     id: 3,
            //     sub: 'Help Center',
            //     links: '/help-center'
            // },
            // {
            //     id: 4,
            //     sub: 'FAQ',
            //     links: '/faqs'
            // },
            
            {
                id: 6,
                sub: 'Login',
                links: '/login'
            },
            {
                id: 7,
                sub: 'Sign Up',
                links: '/signup'
            },
            {
                id: 5,
                sub: 'Connect Wallet',
                links: '/wallet'
            },
            {
                id: 2,
                sub: 'Ranking',
                links: '/ranking'
            },
        ],
    },
    {
        id: 7,
        name: 'Contact',
        links: '/contact',
    },
   
    
]

export default menus;