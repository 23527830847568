

// import img1 from '../images/product-category/product-category-2.jpg'
// import img3 from '../images/product-category/product-category-3.jpg'
// import img4 from '../images/product-category/product-category-5.jpg'

const img1 = "https://pbs.twimg.com/media/FkBPr8GVsAAva1-?format=jpg&name=small"
const img2 = "https://pbs.twimg.com/media/FkBPWv3UoAAYQdI?format=jpg&name=small"
const img3 = "https://pbs.twimg.com/media/FkFr94jaUAAnKZ4?format=jpg&name=small"
const img4 = "https://pbs.twimg.com/media/FkBOj1iVIAYn56W?format=jpg&name=small"
const img5 = "https://pbs.twimg.com/media/FkFr94laUAERfkY?format=jpg&name=small"

const dataCategory = [
    {
        id: 1,
        img: img1,
        title: 'Architectural',
    },
    {
        id: 2,
        img: img2,
        title: 'Interior',
    },
    {
        id: 3,
        img: img3,
        title: 'Graphic Design',
    },
    {
        id: 4,
        img: img4,
        title: 'Product Design',
    },
    {
        id: 5,
        img: img5,
        title: 'illustration',
    },

   
]

export default dataCategory;