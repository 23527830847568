
import Axios from 'axios'
// import React, { useEffect, useState} from 'react';

import avt1 from '../images/slider/slider-4.png'

import img1 from '../images/banner/banner-1.jpg'
import img2 from '../images/banner/banner-2.jpg'
import img3 from '../images/banner/banner-3.jpg'
import img4 from '../images/banner/banner-4.jpg'
import img5 from '../images/banner/banner-5.jpg'

// const img1 = "https://scontent.fhan14-1.fna.fbcdn.net/v/t39.30808-6/318957947_101106502860428_1013326224750182998_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=730e14&_nc_ohc=pDFMC6nSWTEAX-8fT7Z&_nc_ht=scontent.fhan14-1.fna&oh=00_AfCcsuQzlpdBwDGJBwVbKaH8WbztOm4SCSxHsAHO39OjpA&oe=63A0AAFF"
// const img2 = "https://scontent-hkt1-1.xx.fbcdn.net/v/t39.30808-6/318516657_101106549527090_4832699846820174592_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=730e14&_nc_ohc=QalWT2mVZQEAX_fgwhw&_nc_ht=scontent-hkt1-1.xx&oh=00_AfBOEFbMXV6D_bh3ZjqzE8Aj6I80vQp7AzoHsv0xDnhrrQ&oe=639B3DB2"
// const img3 = "https://scontent-hkt1-1.xx.fbcdn.net/v/t39.30808-6/319519504_101106526193759_7054534116481360086_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=730e14&_nc_ohc=6nQS7C7xgO4AX_BLt55&tn=j8dlSZKxAz6lHJ0y&_nc_ht=scontent-hkt1-1.xx&oh=00_AfBKZb0kqEacmqEuk2Or2mNLKINoJ5YxFIEPLzVbkS_RIg&oe=639B10D5"
// const img4 = "https://scontent.fhan14-2.fna.fbcdn.net/v/t39.30808-6/318742932_101106579527087_2867935473791930655_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=730e14&_nc_ohc=sVKYjgaiueoAX-1OrJV&tn=B4wOUHx9WrSiymw5&_nc_ht=scontent.fhan14-2.fna&oh=00_AfAGXYKx4iYUElSq2F8e3Tjn80XRbMRGxMjreoNtG1dukg&oe=63A0B4D8"
// const img5 = "https://scontent-hkt1-1.xx.fbcdn.net/v/t39.30808-6/319280216_101106622860416_6208805913155006955_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_ohc=lRGdGhUsWvUAX_Lm1zQ&tn=NOxd5v1sdAolwbJe&_nc_ht=scontent-hkt1-1.xx&oh=00_AfA-2XMiWBcU_0AVA3_pUzfyD_beho3FlgU_QSFpx5gUrQ&oe=639B6215"

// var dataBanner = []
// export function CallApi () {
//     const [banner, setBanner] = useState([])

//  useEffect(() => {
//    Axios.get('https://nact-labs-be.onrender.com/api/v1/banners').then(res => {
//      setBanner(res.data.docs)
//    })
//  },[])
//  dataBanner = banner
//  return (
//    console.log(dataBanner)

//  );
// }


const dataBanner = [
    {
        id: 1,
        
        heading: 'Defind, Collect and Sell Super Rate NFT',
        desc: 'Nact NFTs for you, buy and using design pattern to create own pesornal work',
        img: img1,
        price: '1.72 SOL',
        avt: avt1,
        name: '“Demo deploy git”',
        tag: '@Terodesign Studio'
    },
    {
        id: 2,
        
        heading: 'Defind, Collect and Sell Super Rate NFT ',
        desc: 'Nact NFTs for you, buy and using design pattern to create own pesornal work',
        img: img2,
        price: '4.56 SOL',
        avt: avt1,
        name: '“The Monkey sad ”',
        tag: '@SolvadorDali'
    },
    {
        id: 3,
        
        heading: 'Defind, Collect And Sell Super Rate NFT ',
        desc: 'Nact NFTs for you, buy and using design pattern to create own pesornal work',
        img: img3,
        price: '1.56 wETH',
        avt: avt1,
        name: '“The Monkey sad ”',
        tag: '@SolvadorDali'
    },
    {
        id: 4,
        
        heading: 'Defind, Collect and Sell Super Rate NFT ',
        desc: 'Nact NFTs for you, buy and using design pattern to create own pesornal work',
        img: img4,
        price: '1.56 BNB',
        avt: avt1,
        name: '“The Monkey sad ”',
        tag: '@SolvadorDali'
    },
]

export default dataBanner;